import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import AboutUs from "./AboutUs";
import Arrow from "./Arrow";
import CaseStudy from "./CaseStudy";
import Clients from "./Clients";
import Contact from "./Contact";
import Foundation from "./Foundation";
import Navbar from "./Navbar";
import NiceDay from "./NiceDay";
import OthersAboutUs from "./OthersAboutUs";
import Showreel from "./Showreel";
import ContactForm from "./ContactForm";
import "../styles/App.scss";
import { detectDevice, detectLanguage, responsive, throttle } from "../utils.js";

const languages = ["pl", "en"]

function App() {
    let deviceType = detectDevice()
    const { lang } = useParams();
    const [language, setLanguage] = useState('')
    const [scrolled, setScrolled] = useState(false)
    const [scrolledToBottom, setScrolledToBottom] = useState(false)
    !language && setLanguage(() => detectLanguage(languages))
    
    useEffect(() => {
        if (lang !== undefined && lang === "en") {
          setLanguage("en");
        }
      }, [lang]);
    
    useEffect(() => {
        // Scroll to section from link
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1,
        )
        if (window.location.href.lastIndexOf('#') > 0) {
            setTimeout(() => {
                document.getElementById(href)?.scrollIntoView()
                setScrolled(true)
            },1000)
        }

        setTimeout(() => {
            if (window.scrollY > 0) {
                setScrolled(true)
            }
        },10)

        window.addEventListener('scroll', e => {
            if (!scrolledToBottom && (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 80) {
                setScrolledToBottom(true)
            } else if ((window.scrollY) === 0) {
                setScrolled(false)
                setScrolledToBottom(false)
            }
        })
    
        function setScroll() {
            // check if scrolled for navbar and arrow
            if(deviceType === "desktop") {
                window.addEventListener("wheel", throttle(e => {
                    let delta = null
                    let direction = false
                    if (e.wheelDelta) {
                        delta = e.wheelDelta / 60;
                    } else if (e.detail) {
                        // fallback for Firefox
                        delta = -e.detail / 2
                    }
                    if (delta !== null) {
                        direction = delta > 0 ? "up" : "down"
                    }
                    if (direction == "down") {
                        setScrolled(true)
                    }
                    if (direction == "up") {
                    }
                    return direction
                }, 800))
            } else {
                document.addEventListener('touchstart', throttle(handleTouchStart, 800), false);
                document.addEventListener('touchmove', throttle(handleTouchMove, 800), false);
                let xDown = null;
                let yDown = null;
                function handleTouchStart(e) {
                    e.stopPropagation();
                    xDown = e.touches[0].clientX;
                    yDown = e.touches[0].clientY;
                };
                function handleTouchMove(e) {
                    if ( ! xDown || ! yDown ) {
                        return;
                    }
                    let xUp = e.touches[0].clientX;
                    let yUp = e.touches[0].clientY;
                    let xDiff = xDown - xUp;
                    let yDiff = yDown - yUp;
                    if (Math.abs( xDiff ) > Math.abs( yDiff )) {
                        if ( xDiff > 0  ) {
                            // console.log('left')
                        } else if( xDiff < 0 ) {
                            // console.log('right')
                        }
                    } else if(Math.abs( xDiff ) < Math.abs( yDiff )) {
                        if ( yDiff > 0  ) {
                            // console.log('up')
                            setScrolled(true)
                        } else if( yDiff < 0 ) {
                            // console.log('down')
                        }
                    }
                    /* reset values */
                    xDown = null;
                    yDown = null;
                    return
                }     
                
                // Remove hovers for mobile devices
                try { // prevent exception on browsers not supporting DOM styleSheets properly
                    for (let si in document.styleSheets) {
                      let styleSheet = document.styleSheets[si];
                      if (!styleSheet.rules) continue;
                      for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;
                
                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                          styleSheet.deleteRule(ri);
                        }
                      }
                    }
                } catch (ex) {}
            }
        }
        
        responsive()
        setScroll()
    },[]);

  return (
    // <Router>
        <div className={ `App ${deviceType}` }>
            <Routes>
               <Route path="/:lang" element={<App />} />
            </Routes>
            <Navbar language={ language } languages={ languages } setLanguage={ setLanguage } scrolled={ scrolled } setScrolled={ setScrolled } />
            <Showreel language={ language } setScrolled={ setScrolled } />
            <AboutUs language={ language } />
            <CaseStudy language={ language } deviceType={ deviceType } />
            <Clients language={ language } />
            <OthersAboutUs language={ language } />
            <Foundation language={ language } deviceType={ deviceType } />
            <Contact language={ language } deviceType={ deviceType } />
            <ContactForm language={ language } deviceType={ deviceType } />
            <NiceDay language={ language } />
            <Arrow scrolled={ scrolled } scrolledToBottom={ scrolledToBottom } />
        </div>
    // </Router>
  );
}

export default App;
